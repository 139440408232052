import React from 'react';
import ChatGptUtilityContainer from './ChatGptUtilityContainer';
import AIChartsUtilityModel from './models/AIChartsUtility';
import AIContentFilterChartsModel from './models/AIContentFilterCharts';
import AIContentFilterChartsDetailsModel from './models/AIContentFilterChartsDetails';
import AIFeedbackChartsDetailsModel from './models/AIFeedbackChartsDetails';
import AssetTOC from './models/AssetTOC';
import ChannelsListing from './models/ChannelsListing';
import ChannelsModel from './models/ChannelsModel';
import ChannelsSubType from './models/ChannelsSubType';
import ChannelsTrends from './models/ChannelsTrends';
import ChannelsUtility from './models/ChannelsUtility';
import ChatgptAdminPromptsData from './models/ChatGptAdminPrompts';
import ChatUtilityGetTypesModel from './models/ChatUtilityGetTypesModel';
import ChatgptAdmin from './models/ChatgptAdmin';
import chatutilityIESData from './models/ChatgptIESUser';
import Mapping from './models/ChatgptMapping';
import ChatgptUtility from './models/ChatgptUtility';
import Feedback from './models/Feedback';
import Listing from './models/Listing';
import McqSearch from './models/McqSearch';
import Response from './models/Response';
import EffectivenessData from './models/StudyToolEffectiveness';
import SubTypeResponse from './models/SubTypeModel';
import Summary from './models/Summary';
import Toc from './models/Toc';
import Trends from './models/Trends';
import AIChartsDetailsUtilityService from './service/AIChartsDetailsUtilityService';
import AIChartsUtilityService from './service/AIChartsUtilityService';
import AIContentFilterChartsDetailsService from './service/AIContentFilterChartsDetails';
import AIContentFilterChartsService from './service/AIContentFilterChartsService';
import AssetService from './service/AssetService';
import ChannelsModelService from './service/ChannelsModelService';
import ChannelsSubTypeService from './service/ChannelsSubTypeService';
import ChannelsUtilityService from './service/ChannelsUtilityService';
import ChannelsUtilityTrendsListingService from './service/ChannelsUtilityTrendsListingService';
import ChannelsUtilitytrendsService from './service/ChannelsUtilityTrendsService';
import ChatGptAddAdminPromptsService from './service/ChatGptAddAdminPromptsService';
import ChatGptEditAdminPromptsService from './service/ChatGptEditAdminPromptsService';
import ChatGptGetAdminPromptsService from './service/ChatGptGetAdminPromptsService';
import ChatGptUtilityFeedbackService from './service/ChatGptUtilityFeedbackService';
import ChatgptUtilityIESService from './service/ChatGptUtilityIESService';
import ChatGptUtilityListingService from './service/ChatGptUtilityListingService';
import ChatGptUtilityMappingService from './service/ChatGptUtilityMappingService';
import ChatGptUtilityMcqEditService from './service/ChatGptUtilityMcqEditService';
import ChatGptUtilityResponseService from './service/ChatGptUtilityResponseService';
import ChatgptUtilityService from './service/ChatGptUtilityService';
import ChatGptUtilitySummaryService from './service/ChatGptUtilitySummaryService';
import ChatGptUtilitySummaryStatusUpdateService from './service/ChatGptUtilitySummaryStatusUpdateService';
import ChatGptUtilitySummaryUpdateService from './service/ChatGptUtilitySummaryUpdateService';
import ChatGptUtilityTocAddService from './service/ChatGptUtilityTocAddService';
import ChatGptUtilityTocService from './service/ChatGptUtilityTocService';
import ChatGptUtilityTrendsService from './service/ChatGptUtilityTrendsService';
import ChatUtilityGetTypesService from './service/ChatUtilityGetTypesService';
import ChatGptUtilitySubTypeService from './service/ChatUtilitySubTypeService';
import ChatgptAdminService from './service/ChatgptAdminService';
import ChatGptEditAdminService from './service/ChatgptEditAdminService';
import ChatGptGetAdminervice from './service/ChatgptGetAdminService';
import EffectivenessInitiateService from './service/EffectivenessInitiateService';
import EffectivenessMetadataService from './service/EffectivenessMetadataService';
import McqStatusDeleteService from './service/McqStatusDeleteService';
import McqStatusUpdateService from './service/McqStatusUpdateService';
import McqUtilityBookSearchService from './service/McqUtilityBookSearchService';
import StudyToolEffectivenessService from './service/StudyToolEffectivenessService';
// TODO: Hide Reports tab
// '/chatgptutility/aistudytools/reports',
// '/chatgptutility/channels/reports',

export default {
  path: [
    '/chatgptutility/aistudytools',
    '/chatgptutility/aistudytools/feedback',
    '/chatgptutility/aistudytools/trends',
    '/chatgptutility/aistudytools/trends/charts',
    '/chatgptutility/aistudytools/trends/clustering',
    '/chatgptutility/aistudytools/trends/unanswered',
    '/chatgptutility/aistudytools/mcq/view/:bookId',
    '/chatgptutility/aistudytools/mcq/view',
    '/chatgptutility/aistudytools/mcq/add/:bookId',
    '/chatgptutility/aistudytools/mcq/add',
    '/chatgptutility/aistudytools/mcq/edit',
    '/chatgptutility/aistudytools/mcq/edit/:bookId',
    '/chatgptutility/aistudytools/titlesearch',
    '/chatgptutility',
    '/chatgptutility/channels',
    '/chatgptutility/channels/feedback',
    '/chatgptutility/channels/trends',
    '/chatgptutility/admin',
    '/chatgptutility/aistudytools/summary/view/:bookId',
    '/chatgptutility/aistudytools/summary/view',
    '/chatgptutility/aistudytools/summary/add/:bookId',
    '/chatgptutility/aistudytools/summary/add',
    '/chatgptutility/aistudytools/summary/edit',
    '/chatgptutility/aistudytools/summary/edit/:bookId',
    '/chatgptutility/aistudytools/trends/effectiveness',
    '/chatgptutility/aistudytools/promptmgt',
    '/chatgptutility/channels/promptmgt',
    '/chatgptutility/*'
  ],
  exact: true,
  services: [
    new ChatgptUtilityService(),
    new ChatGptUtilityFeedbackService(),
    new ChatGptUtilityResponseService(),
    new ChatgptUtilityIESService(),
    new ChatGptUtilityTrendsService(),
    new ChatGptUtilityMappingService(),
    new ChatGptUtilitySubTypeService(),
    new ChatGptUtilityListingService(),
    new AssetService(),
    new ChatGptUtilityTocService(),
    new ChatGptUtilitySummaryService(),
    new ChatGptUtilitySummaryUpdateService(),
    new ChatGptUtilitySummaryStatusUpdateService(),
    new ChatGptUtilityMcqEditService(),
    new ChatGptUtilityTocAddService(),
    new McqStatusDeleteService(),
    new McqStatusUpdateService(),
    new McqUtilityBookSearchService(),
    new ChannelsUtilityService(),
    new ChannelsUtilityTrendsListingService(),
    new ChannelsUtilitytrendsService(),
    new ChannelsSubTypeService(),
    new ChannelsModelService(),
    new ChatgptAdminService(),
    new ChatGptGetAdminervice(),
    new ChatGptEditAdminService(),
    new ChatGptGetAdminPromptsService(),
    new ChatGptAddAdminPromptsService(),
    new ChatGptEditAdminPromptsService(),
    new StudyToolEffectivenessService(),
    new EffectivenessInitiateService(),
    new EffectivenessMetadataService(),
    new ChatUtilityGetTypesService(),
    new AIChartsUtilityService(),
    new AIContentFilterChartsService(),
    new AIChartsDetailsUtilityService(),
    new AIContentFilterChartsDetailsService()
  ],
  stores: [
    {
      name: 'effectivenessData',
      spec: EffectivenessData,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'ChatgptUtilityIESUserData',
      spec: chatutilityIESData,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'chatgptUtility',
      spec: ChatgptUtility,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptFeedbackUtility',
      spec: Feedback,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptResponseUtility',
      spec: Response,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'ChatUtilitySubTypeResponse',
      spec: SubTypeResponse,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'chatgptTrendsUtility',
      spec: Trends,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptMappingUtility',
      spec: Mapping,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptListingUtility',
      spec: Listing,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'assetTOC',
      spec: AssetTOC,
      saveLocally: false,
      initialState: {
        id: '',
        title: '',
        children: [
          {
            id: '',
            title: '',
            type: '',
            assetType: '',
            uri: '',
            required: false
          }
        ]
      }
    },
    {
      name: 'chatgptTocUtility',
      spec: Toc,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptSummaryUtility',
      spec: Summary,
      saveLocally: false,
      initialState: {
        chatgptSummaryUtility: {}
      }
    },
    {
      name: 'mcqSearch',
      spec: McqSearch,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'channelsUtility',
      spec: ChannelsUtility,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsSubType',
      spec: ChannelsSubType,
      saveLocally: false,
      initialState: {
        channelsSubType: {}
      }
    },
    {
      name: 'channelsListingUtility',
      spec: ChannelsListing,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsTrendsUtility',
      spec: ChannelsTrends,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsModelData',
      spec: ChannelsModel,
      saveLocally: false,
      initialState: {
        channelsModelData: {}
      }
    },
    {
      name: 'chatgptAdmin',
      spec: ChatgptAdmin,
      saveLocally: false,
      initialState: {
        chatgptAdmin: {}
      }
    },
    {
      name: 'chatgptAdminPrompts',
      spec: ChatgptAdminPromptsData,
      saveLocally: false,
      initialState: {
        chatgptAdminPrompts: {}
      }
    },
    {
      name: 'chatUtilityGetTypes',
      spec: ChatUtilityGetTypesModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiChartsUtility',
      spec: AIChartsUtilityModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiContentfilterCharts',
      spec: AIContentFilterChartsModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiContentfilterChartsDetails',
      spec: AIContentFilterChartsDetailsModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiFeedbackChartsDetails',
      spec: AIFeedbackChartsDetailsModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    }
  ],
  render: (props) => (
    <ChatGptUtilityContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
